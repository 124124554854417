<template>
  <div id="cms-cash-count-show">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Cash Count - Show</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-row>
              <b-col class="col-sm-5" style="margin-left: 6em">
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Date:"
                        label-for="date"
                >
                  <b-form-input
                          id="date"
                          type="date"
                          v-model="cashCount.date"
                          style="margin-left: 2em;"
                          disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€200:"
                      label-for="two-hundred"
              >
                <b-form-input
                        id="two-hundred"
                        type="number"
                        v-model="cashCount.two_hundred"
                        disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€2.00:"
                      label-for="two"
              >
                <b-form-input
                        id="two"
                        type="number"
                        v-model="cashCount.two"
                        disabled
                ></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€100:"
                      label-for="hundred"
              >
                <b-form-input
                        id="hundred"
                        type="number"
                        v-model="cashCount.hundred"
                        disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€1.00:"
                      label-for="one"
              >
                <b-form-input id="one" type="number" v-model="cashCount.one" disabled></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 50 :"
                      label-for="fifty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.fifty" disabled></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.50:"
                      label-for="fifty_cent"
              >
                <b-form-input id="fifty_cent" type="number" v-model="cashCount.fifty_cent" disabled></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 20 :"
                      label-for="twenty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.twenty" disabled></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.20:"
                      label-for="twenty_cent"
              >
                <b-form-input id="twenty_cent" type="number" v-model="cashCount.twenty_cent" disabled></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 10 :"
                      label-for="ten"
              >
                <b-form-input id="ten" type="number" v-model="cashCount.ten" disabled></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.10:"
                      label-for="ten_cent"
              >
                <b-form-input id="ten_cent" type="number" v-model="cashCount.ten_cent" disabled></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label=" € 5 :"
                      label-for="five"
              >
                <b-form-input id="five" type="number" v-model="cashCount.five" disabled style="margin-left: 0.2em" ></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.05:"
                      label-for="five_cent"
              >
                <b-form-input id="five_cent" type="number" v-model="cashCount.five_cent" disabled style="margin-left: 0.2em"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="7"
                      label-cols-lg="7"
                      label=" Total € :"
                      label-for="total"
                      style="margin-right: 6.5em"
              >
                <b-form-input
                        id="total"
                        type="number"
                        v-model="cashCount.total"
                        style="margin-left: 0.2em"
                        disabled
                ></b-form-input>
              </b-form-group>
              <router-link class="link-color" to="/cms/cash-count" style="margin-right: 0.1em" @click.prevent>
                <b-button type="reset" variant="success">Back</b-button>
              </router-link>
              <router-link class="link-color" :to="{ name: 'CmsCashCountUpdate', params: { id: this.$route.params.id }}" @click.prevent>
                <b-button  type="submit" variant="warning">Edit</b-button>
              </router-link>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'

export default {
  data() {
    return {
      loading: true,
      cashCount: [],
    }
  },
  components: {
    SideMenu
  },
  beforeCreate () {
    this.axios
        .get(`https://batavia-backend.herokuapp.com/api/cash-count/${this.$route.params.id}`)
        .then(response => (
            this.cashCount = response.data,
            this.cashCount['total'] = this.cashCount['total'].toFixed(2)
        ))
        .finally(() => {
          this.loading = false
        })
  }
}
</script>